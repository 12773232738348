import { useRef } from 'react'
import { graphql, Script } from 'gatsby'

const Tagbox = ({ templateId, sys }) => {
  const tagBoxRef = useRef(null)

  return (
    <>
      <Script
        type="text/javascript"
        src="https://widget.taggbox.com/embed-lite.min.js"
      />
      <div
        ref={tagBoxRef}
        key={sys.id}
        className="taggbox"
        style={{
          width: '100%',
          height: '100%',
        }}
        data-widget-id={templateId}
        data-tags="false"
      ></div>
    </>
  )
}

export const query = graphql`
  fragment Tagbox on contentful_Tagbox {
    __typename
    sys {
      id
    }
    templateId
  }
`

export default Tagbox
